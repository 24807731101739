import auth from '../../auth';

export const getDefaultHeaders = () => {
  const authToken = auth.getAccessToken();

  return {
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
    Accept: 'application/json',
    Authorization: 'Bearer ' + authToken,
  };
};
