import React, { useState, useEffect } from 'react';
import { Card, Button, ButtonGroup } from '@cimpress/react-components';
import { Toast } from './Toast';
import { ImportByText } from './ImportByText';
import { ImportByTemplate } from './ImportByTemplate';
import { useParams } from 'react-router-dom';
import { getTranslations } from '../api/get-translations';
import { getLocalizationRetranslationData } from '../api/get-localization-retranslation-data';
import config from '../config';

export const ImportPageS3 = () => {
  const [message, setMessage] = useState({ message: '', severity: 'info' });
  let { accountId, areaId, batchId } = useParams();
  const [translations, setTranslations] = useState([]);
  const [counts, setCounts] = useState({ textsCount: 0, templatesCount: 0 });
  const [retranslations, setRetranslations] = useState([]);

  const [mode, setMode] = useState('byTemplate');

  const setToast = (messages, severity) => {
    var d = new Date();
    var version = d.toString();
    setMessage({ messages, severity, version });
  };

  const cardHeader = <div>Import From S3</div>;

  useEffect(() => {
    const tenant = { accountId, areaId };
    batchId &&
      getTranslations(tenant, batchId).then((res) => {
        const localizations = Object.entries(res).map(([key, value]) => ({
          culture: key,
          items: value,
        }));

        const items = localizations.flatMap((l) =>
          Object.keys(l.items).map((key) => ({
            adtifUrl: l.items[key].adtif,
            localizationKey: key,
          })),
        );
        const distinctLocalizationKeys = [...new Set(items.map((x) => x.localizationKey))];
        const retranslationPromises = distinctLocalizationKeys.map((localizationKey) =>
          getLocalizationRetranslationData(localizationKey),
        );
        const derivedCultures = config.mockAccountsConfiguration[accountId]?.area[areaId]?.derivedCultures ?? {};

        const groupRetranslationByLocalizationId = (reTranslationItems) => {
          const retranslationMap = reTranslationItems.reduce((a, item) => {
            if (item && Object.keys(item).length > 0) {
              const culture = Object.keys(item)[0];
              const localizationId = item[culture].localizationId;
              const derivedItemsMap = Object.keys(item)
                .flatMap((itemKey) => {
                  if (itemKey in derivedCultures) {
                    const culturesToDerive = derivedCultures[itemKey];
                    const derivedItems = culturesToDerive.map((cultureToDerive) => {
                      return { ...item[itemKey], culture: cultureToDerive };
                    });
                    return derivedItems;
                  }
                })
                .filter((x) => x);
              derivedItemsMap.map((x) => (item[x.culture] = x));
              a[localizationId] = item;
            }
            return a;
          }, {});
          setRetranslations(retranslationMap);
        };
        Promise.all(retranslationPromises).then((reTranslationItems) =>
          groupRetranslationByLocalizationId(reTranslationItems),
        );
        // setRetranslations(Promise.all(retranslationPromises))
        const distinctTemplates = [...new Set(items.map((x) => x.adtifUrl))];

        setCounts({ textsCount: distinctLocalizationKeys.length, templatesCount: distinctTemplates.length });
        setTranslations(res);
      });
  }, [batchId, accountId, areaId]);

  return (
    <>
      <div className="container-fluid designs-page">
        <div className="row">
          <div className="col-sm-12 col-md-10 col-md-offset-1">
            <Card header={cardHeader}>
              <ButtonGroup>
                <Button type={mode === 'byTemplate' ? 'primary' : 'default'} onClick={() => setMode('byTemplate')}>
                  Templates ({counts.templatesCount})
                </Button>
                <Button type={mode === 'byText' ? 'primary' : 'default'} onClick={() => setMode('byText')}>
                  Texts ({counts.textsCount})
                </Button>
              </ButtonGroup>
              {mode === 'byText' && (
                <ImportByText setToast={setToast} translations={translations} retranslation={retranslations} />
              )}
              {mode === 'byTemplate' && (
                <ImportByTemplate setToast={setToast} translations={translations} retranslation={retranslations} />
              )}
              <Toast messages={message.messages} severity={message.severity} version={message.version} />
            </Card>
          </div>
        </div>
      </div>
    </>
  );
};
