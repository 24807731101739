import React, { useState, useEffect, useCallback } from 'react';
import { Table, Button } from '@cimpress/react-components';
import { getLocalizationTasks } from '../api/get-missing-localizations';

const MissingLocalizations = ({ onEdit, tenant, onPageChange, currentPage, currentPageSize, loadData }) => {
  const [pageCount, setPageCount] = useState(0);
  const [pageSize, setPageSize] = useState(currentPageSize ? currentPageSize : 10);
  const [page, setPage] = useState(currentPage ? currentPage : 0);
  const [localizations, setLocalizations] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchData = useCallback(
    (page, pageSize) => {
      setLoading(true);
      const offset = page * pageSize;

      (loadData ? loadData(offset, pageSize) : getLocalizationTasks(offset, pageSize, tenant))
        .then((localization) => {
          const countOfPages = Math.ceil(localization.count / pageSize);

          setPageCount(countOfPages);
          setLocalizations(localization.localizations);
          setLoading(false);
        })
        .catch((err) => {
          setPageCount(0);
          setLocalizations();
          setLoading(false);
          console.log('Error while getting missing localizations', err);
          //onError(`Error while getting missing localizations ${err}`);
        });
    },
    [loadData, tenant],
  );

  useEffect(() => {
    fetchData(page, pageSize);
  }, [fetchData, page, pageSize]);

  const columns = [
    {
      Header: 'EN-US LOCALIZATION',
      id: 'text',
      accessor: (r) =>
        r.preview ? (
          <a href={r.preview} target="new">
            {<div dangerouslySetInnerHTML={{ __html: r.defaultText }}></div>}
          </a>
        ) : (
          <div dangerouslySetInnerHTML={{ __html: r.defaultText }}></div>
        ),
    },
    {
      Header: 'Missing Cultures',
      id: 'missingCultures',
      disableSortBy: true,
      accessor: (r) => r.missingCultures.length,
    },
    {
      Header: '',
      id: 'action',
      disableSortBy: true,
      width: 100,
      accessor: (r) => (
        <Button
          type="primary"
          onClick={() => {
            onEdit(r.localizationId);
          }}
        >
          Detail
        </Button>
      ),
    },
  ];

  return (
    <>
      <Table
        columns={columns}
        data={localizations}
        InlineEdit={false}
        showPagination={true}
        sortable={false}
        minRows={0}
        pageSize={pageSize}
        page={page}
        pages={pageCount}
        manual
        loading={loading}
        onPageChange={async (index) => {
          await setPage(index);
          onPageChange(index, pageSize);
        }}
        onPageSizeChange={async (size) => {
          if (size !== pageSize) {
            await setPageSize(size);
            await setPage(0);
            onPageChange(0, size);
          }
        }}
      ></Table>
    </>
  );
};

export default MissingLocalizations;
