import React, { useState } from 'react';
import { Button, Table } from '@cimpress/react-components';
import {
  validateFile,
  extractCultureFromFilename,
  importMissingLocalization,
} from '../api/import-missing-localizations';
import { getLocalizations } from '../api/get-localization-batch';
import { getRequiredCultures } from '../api/required-cultures';

/*
 * Component is used for manual upload of translations - rarely used, if ever
 */

export const Import = ({ setToast, onComplete, tenant }) => {
  const [importing, setImporting] = useState(false);
  const hiddenFileInput = React.useRef(null);
  const [previewData, setPreviewData] = useState([]);
  const [importedCultures, setImportedCultures] = useState([]);
  const [enUsTranslations, setEnUsTranslations] = useState({});
  const [localizations, setLocalizations] = useState([]);
  const [loading, setLoading] = useState(true);

  const readUploadedFileAsText = (inputFile) => {
    const temporaryFileReader = new FileReader();

    return new Promise((resolve, reject) => {
      temporaryFileReader.onerror = () => {
        temporaryFileReader.abort();
        reject(new DOMException('Problem parsing input file.'));
      };

      temporaryFileReader.onload = () => {
        resolve(temporaryFileReader.result);
      };
      temporaryFileReader.readAsText(inputFile);
    });
  };

  async function handleFiles(event) {
    setLoading(true);
    setImporting(true);
    const files = Array.from(event.target.files);
    event.target.value = null;
    const cultureLocalizationPromises = files.map(async (f) => ({
      name: f.name,
      content: await readUploadedFileAsText(f),
    }));
    const cultureLocalizations = await Promise.all(cultureLocalizationPromises);
    const requiredCultures = await getRequiredCultures(tenant);
    const validationResults = cultureLocalizations.map((x) => validateFile(x.name, x.content, requiredCultures));

    if (validationResults.some((validation) => !validation.isValid)) {
      const messages = validationResults.filter((r) => !r.isValid).map((r) => r.message);
      setToast(messages, 'danger');
    } else {
      const localizations = cultureLocalizations.map((l) => ({
        culture: extractCultureFromFilename(l.name),
        items: JSON.parse(l.content),
      }));
      const items = localizations.flatMap((l) =>
        Object.keys(l.items).map((key) => ({
          culture: l.culture,
          localizationId: key,
          localization: l.items[key].Value ? l.items[key].Value : l.items[key].value,
        })),
      );

      const groupedByLocalizationId = items.reduce((acc, i) => {
        if (!acc[i.localizationId]) {
          acc[i.localizationId] = {};
        }

        acc[i.localizationId][i.culture] = i.localization;
        return acc;
      }, {});

      const previewData = Object.keys(groupedByLocalizationId).map((k) => ({
        localizationId: k,
        isChecked: true,
        translations: groupedByLocalizationId[k],
      }));
      const ids = [...new Set(items.map((i) => i.localizationId))];
      const defaultEnUsTranslation = await getLocalizations('en-us', ids);

      setEnUsTranslations(defaultEnUsTranslation);

      const importedCultures = localizations.map((l) => l.culture);

      setLocalizations(localizations);
      setImportedCultures(importedCultures);
      setPreviewData(previewData);
      setLoading(false);
    }
  }

  async function processLocalizations() {
    const localizationsFiltered = localizations.map((localization) => {
      return {
        ...localization,
        items: Object.keys(localization.items).reduce((acc, localizationId) => {
          const isChecked = previewData.filter((pd) => pd.localizationId === localizationId)[0]?.isChecked;
          if (isChecked && localization.items[localizationId].value) {
            acc[localizationId] = localization.items[localizationId];
          }
          return acc;
        }, {}),
      };
    });

    const promises = localizationsFiltered.map((x) => importMissingLocalization(tenant, x.culture, x.items));

    try {
      await Promise.all(promises);
      onComplete();
    } catch (e) {
      setToast([e], 'danger');
    }
    setImporting(false);
  }

  function importMemsource() {
    hiddenFileInput.current.click();
  }

  const changeIsChecked = (isChecked, localizationId) => {
    const newPreviewData = previewData.map((pd) =>
      pd.localizationId !== localizationId ? pd : { ...pd, isChecked: isChecked },
    );

    setPreviewData(newPreviewData);
  };

  const columns = [
    {
      Header: '',
      id: 'CheckBox',
      width: 50,
      accessor: (r) => (
        <input
          type="checkbox"
          checked={r.isChecked}
          onChange={(e) => changeIsChecked(e.target.checked, r.localizationId)}
        />
      ),
    },
    {
      Header: 'EN-US',
      id: '.EN-US',
      accessor: (r) => <div dangerouslySetInnerHTML={{ __html: enUsTranslations[r.localizationId] }}></div>,
    },
    ...importedCultures?.map((culture) => ({
      Header: culture,
      id: culture,
      minWidth: 100,
      accessor: (r) => (
        <div className="wordwrap" dangerouslySetInnerHTML={{ __html: r.translations[culture.toLowerCase()] }}></div>
      ),
    })),
  ];

  return (
    <>
      <input ref={hiddenFileInput} multiple="multiple" style={{ display: 'none' }} type="file" onChange={handleFiles} />
      <div>
        {!importing && (
          <Button onClick={() => importMemsource()} disabled={importing} style={{ margin: 5 }}>
            Select Import Files
          </Button>
        )}
      </div>
      <div>
        {importing && (
          <Table
            columns={columns}
            data={previewData}
            InlineEdit={false}
            showPagination={true}
            sortable={false}
            minRows={0}
            pageSize={50}
            loading={loading}
            className="-striped -highlight"
            style={{ overflowX: 'auto' }}
          ></Table>
        )}
      </div>
      <div>
        {importing && (
          <Button onClick={() => processLocalizations()} style={{ margin: 5 }} type="primary">
            Import selected
          </Button>
        )}
        {importing && (
          <Button onClick={() => setImporting(false)} style={{ margin: 5 }}>
            Cancel
          </Button>
        )}
      </div>
    </>
  );
};
