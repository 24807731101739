import React, { useState, useEffect } from 'react';
import { Modal, TextField, Button, Alert } from '@cimpress/react-components';
import postRetranslation from '../api/post-retranslation';

const RetranslationDialog = ({
  isVisible,
  closeDialog,
  localizationId,
  culture,
  value,
  onSave,
  initialComment,
  initialLength,
  mode,
}) => {
  const [comment, setComment] = useState('');
  const [length, setLength] = useState('');
  const [alertDismissed, setAlertDismissed] = useState(true);

  const toggleDismissed = () => setAlertDismissed(!alertDismissed);

  const onClose = () => {
    if (mode === 'new') {
      setComment('');
      setLength('');
    } else {
      setComment(initialComment);
      setLength(initialLength);
    }

    closeDialog();
  };

  useEffect(() => {
    setComment(initialComment);
    setLength(initialLength);
  }, [initialComment, initialLength]);

  return (
    <Modal
      bsStyle="info"
      show={isVisible}
      title={mode === 'new' ? 'Request new translation' : 'Update translation request'}
      editMode={true}
      closeButton={true}
      onRequestHide={onClose}
      footer={
        <div className="container-fluid">
          <div className="row">
            <Button
              className="col-sm-12 col-md-12"
              onClick={() => {
                if (!comment || isNaN(length)) {
                  setAlertDismissed(false);
                  return;
                }

                setAlertDismissed(true);
                const maxLength = isNaN(parseInt(length)) ? null : parseInt(length);
                postRetranslation(localizationId, culture, {
                  comment: comment,
                  sourceCulture: 'en-us',
                  value: value,
                  length: maxLength,
                }).then(() => {
                  onSave();
                  onClose();
                });
              }}
            >
              Save
            </Button>
          </div>
        </div>
      }
    >
      <TextField
        type="textarea"
        name="localization"
        value={comment}
        label="Comment:"
        onChange={(e) => {
          setComment(e.target.value);
        }}
      />
      <TextField
        type="number"
        name="localization"
        value={length}
        label="Length:"
        onChange={(e) => {
          setLength(e.target.value);
        }}
      />
      <Alert
        title="Validation Error"
        message="Comment and Length should be provided."
        dismissed={alertDismissed}
        onDismiss={toggleDismissed}
      ></Alert>
    </Modal>
  );
};

export default RetranslationDialog;
