import config from '../config';
import { ApiError } from './ApiError';
import { Tenant } from './model/tenant';
import { getDefaultHeaders } from './helpers/api-helpers';

export async function exportMissingLocalization(tenant: Tenant, sourceCulture: string) {
  const endpoint = `/api/v2/accounts/${tenant.accountId}/content-area/${tenant.areaId}/localizations/${sourceCulture}:export`;
  const url = `${config.localizationUIServerUrl}${endpoint}`;
  const results = await fetchExportData(url);

  if (!results.ok) {
    //error
    throw new ApiError('export failed', results);
  }

  return await results.json();
}

const fetchExportData = async (url: string) =>
  await fetch(url, {
    method: 'GET',
    headers: getDefaultHeaders(),
  });
