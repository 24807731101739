import config from '../config';
import { ApiError } from './ApiError';
import { getDefaultHeaders } from './helpers/api-helpers';
import { Tenant } from './model/tenant';

export async function importLocalizationBatch(tenant: Tenant, batchId: string) {
  const endpoint = `/api/v2/accounts/${tenant.accountId}/content-area/${tenant.areaId}/localizations/${batchId}:batchImport`;
  const url = `${config.localizationUIServerUrl}${endpoint}`;
  const response = await sendImportData(url);

  if (!response.ok) {
    if (response.status === 403) {
      throw new ApiError('Not enough permissions to import', response);
    }

    throw new ApiError('Import missing localization failed', response);
  }

  return response;
}

const sendImportData = async (url: string) => {
  return await fetch(url, {
    method: 'POST',
    headers: getDefaultHeaders(),
  });
};
