import React, { useEffect, useState } from 'react';
import { Button, Table, Card } from '@cimpress/react-components';
import { getAvailableTranslations } from '../api/get-available-translations';
import { useHistory, useParams } from 'react-router-dom';
import { Toast } from './Toast';

export const ImportBatchesPage = () => {
  const [message] = useState({ message: '', severity: 'info' });
  let { accountId, areaId } = useParams();

  const history = useHistory();

  const cardHeader = <div>Import From S3</div>;
  const [translationFolders, setTranslationFolders] = useState([]);

  const parseDate = (folderPath) => {
    const dateString = folderPath.replace('output/', '').replace('/', '').replace('-retranslation', '');

    const dateParts = dateString.split('T');
    const datePartTime = dateParts[1].replace(new RegExp('-', 'g'), ':');
    const dateStringFixed = `${dateParts[0]}T${datePartTime}`;
    return new Date(Date.parse(dateStringFixed));
  };

  const getType = (folderPath) => {
    return folderPath.includes('retranslation/') ? 'Retranslated' : 'Regular';
  };

  useEffect(() => {
    const tenant = { accountId, areaId };
    getAvailableTranslations(tenant).then((res) => {
      const folders = res.map((r) => ({
        folder: r.folderName,
        state: r.state,
        displayFolder: parseDate(r.folderName).toLocaleString('en-US'),
        type: getType(r.folderName),
      }));

      setTranslationFolders(folders);
    });
  }, [accountId, areaId]);

  const columnsFolders = [
    { Header: 'Memsource translated batches', id: 'translationFolder', accessor: (r) => r.displayFolder },
    { Header: 'Type', id: 'type', accessor: (r) => r.type },
    { Header: 'state', id: 'state', accessor: (r) => r.state },

    {
      Header: '',
      id: 'import',
      accessor: (r) => (
        <Button
          onClick={() => {
            btnTranslationFolderClicked(r.folder, r.state);
          }}
          disabled={!(r.state === 'Translated' || r.state === 'Imported')}
        >
          Review
        </Button>
      ),
    },
  ];

  function btnTranslationFolderClicked(folder, state) {
    state === 'Imported'
      ? history.push(`/Accounts/${accountId}/ContentArea/${areaId}/Batches/${encodeURIComponent(folder)}?imported=true`)
      : history.push(`/Accounts/${accountId}/ContentArea/${areaId}/Batches/${encodeURIComponent(folder)}`);
  }

  return (
    <>
      <div className="container-fluid designs-page">
        <div className="row">
          <div className="col-sm-12 col-md-10 col-md-offset-1">
            <Card header={cardHeader}>
              <Table
                columns={columnsFolders}
                data={translationFolders}
                InlineEdit={false}
                showPagination={true}
                sortable={false}
                minRows={0}
                pageSize={50}
                loading={false}
                className="-striped -highlight"
                style={{ overflowX: 'auto' }}
              ></Table>
              <Toast messages={message.messages} severity={message.severity} version={message.version} />
            </Card>
          </div>
        </div>
      </div>
    </>
  );
};
