import React, { useState, useEffect } from 'react';
import { Card, Button } from '@cimpress/react-components';
import { ImportLocalizationList } from './ImportLocalizationList';
import { getDesignPreviewUrl } from '../api/design-preview';
import { getTranslatedAdtif } from '../api/get-translated-adtif';
import { useLocation, useParams, useHistory } from 'react-router-dom';
import { getDesignAssets } from '../api/get-design-assets';
import { getTranslationsForCulture } from '../api/get-translations';
import { getLocalizationRetranslationData } from '../api/get-localization-retranslation-data';
import { extractTemplateIdFromAdtifUrl } from './Helpers';
import { camBaseUrl, contentAuthoringAreaId } from '../config.ts';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export const ImportTemplateDetailPage = () => {
  const history = useHistory();
  let { accountId, areaId, batchId, cultureId } = useParams();
  let query = useQuery();
  const [localizations, setLocalizations] = useState([]);
  const [version, setVersion] = useState(0);

  const adtifUrl = query.get('adtifUrl');
  const isImported = query.get('imported');
  const templateId = extractTemplateIdFromAdtifUrl(adtifUrl);

  const cardHeader = (
    <div>
      <div>
        <a rel="noopener noreferrer" href={getDesignPreviewUrl(adtifUrl)} target="_blank">
          Original Template
        </a>
      </div>
      <div style={{ 'margin-top': '5px' }}>Culture: {cultureId}</div>
    </div>
  );

  const tenant = { accountId, areaId };
  useEffect(() => {
    console.log(version); // don't delete this is needed for static analysis.
    const getRetranslation = async (localizationId) => {
      var retranslation = await getLocalizationRetranslationData(localizationId);
      return retranslation ? retranslation[cultureId] : null;
    };

    async function fetchLocalizations() {
      const designAssets = await getDesignAssets(adtifUrl, 'en-us', { accountId, areaId });
      const translations = await getTranslationsForCulture({ accountId, areaId }, batchId, cultureId);

      const newLocalizationsPromise = designAssets
        .filter((da) => da.isNewPlatform)
        .map(async (da) => ({
          localizationId: da.localizationId,
          value: da.value,
          translatedValue: translations[cultureId][da.localizationId]?.value,
          retranslation: await getRetranslation(da.localizationId),
        }));

      const newLocalizations = await Promise.all(newLocalizationsPromise);
      return newLocalizations;
    }

    fetchLocalizations().then((newLocalizations) => setLocalizations(newLocalizations));
  }, [adtifUrl, batchId, cultureId, accountId, areaId, version]);

  const translatedCultureAdtif = getTranslatedAdtif(
    tenant,
    encodeURIComponent(batchId),
    encodeURIComponent(adtifUrl),
    cultureId,
  );
  const designPreviewUrl = getDesignPreviewUrl(translatedCultureAdtif, 500);

  const designTemplate = (
    <div className="row" style={{ marginBottom: 15 }}>
      <div className="col-sm-6 col-md-3">
        <a rel="noopener noreferrer" href={designPreviewUrl} target="_blank">
          <img
            src={designPreviewUrl}
            alt="preview"
            className="img-responsive"
            style={{ border: '1px dotted #0092b5' }}
          />
        </a>
      </div>
      <div className="col-sm-6 col-md-9">
        <ImportLocalizationList
          localizations={localizations}
          culture={cultureId}
          onSave={() => {
            setVersion(version + 1);
          }}
        />
        {templateId && (
          <Button
            type="primary"
            href={`${camBaseUrl}/accounts/${accountId}/contentarea/${contentAuthoringAreaId}/designs?search=${templateId}`}
            target="_blank"
          >
            Show in CAM
          </Button>
        )}
      </div>
    </div>
  );

  return (
    <>
      <div className="container-fluid designs-page">
        <h2>
          <Button
            onClick={() => {
              isImported
                ? history.push(
                    `/Accounts/${accountId}/ContentArea/${areaId}/Batches/${encodeURIComponent(batchId)}?imported=true`,
                  )
                : history.push(`/Accounts/${accountId}/ContentArea/${areaId}/Batches/${encodeURIComponent(batchId)}`);
            }}
            type="link"
          >
            {'<<'}
          </Button>
          Template Preview
        </h2>
        <div className="row">
          <div className="col-sm-12 col-md-12">
            <Card header={cardHeader}>
              {designTemplate}
              {/* <Toast messages={message.messages} severity={message.severity} version={message.version} /> */}
            </Card>
          </div>
        </div>
      </div>
    </>
  );
};
