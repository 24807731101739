import { centralizedAuth } from '@cimpress/simple-auth-wrapper';

export const CLIENT_ID = 'ZQmQXXCxEmH3rE7T3osUB5B0db6ZPSOf'; // https://developer.cimpress.io/clients/ZQmQXXCxEmH3rE7T3osUB5B0db6ZPSOf/settings

const auth = new centralizedAuth({
  clientID: CLIENT_ID,
  redirectRoute: '/',
  leeway: 300,
});

auth.on('tokenExpired', () => {
  auth.login({ nextUri: window.location.pathname || '/', forceLogin: true });
});

export default {
  auth,
  getAccessToken: () => localStorage.getItem('accessToken'),
  getProfile: () => JSON.parse(localStorage.getItem('profile')),
  ensureAuthentication: auth.ensureAuthentication,
  isLoggedIn: auth.isLoggedIn(),
  login: auth.login,
  logout: () => auth.logout('/'),
};
