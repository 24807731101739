import config from '../config';
import { getRequiredCultures } from './required-cultures';
import { ApiError } from './ApiError';
import { Tenant } from './model/tenant';
import { getDefaultHeaders } from './helpers/api-helpers';

export function getDesignId(id: string): string {
  const isTemplateToken = id.includes('..');

  if (isTemplateToken) {
    const versionIndex = id.indexOf(':v');

    const endIndex = versionIndex >= 0 ? versionIndex : id.indexOf('..');

    return id.substring(1, endIndex);
  }

  return id;
}

export function getDTSDesignUrl(designId: string): string {
  return `${config.designTemplateServiceURl}/api/v3/designs/${designId}`;
}

export function getAdtifUrl(dtsUrl: string): string {
  const endpoint = `/api/v2/DTS:adtif?designUrl=${dtsUrl}`;
  const adtifUrl = `${config.localizationUIServerUrl}${endpoint}`;
  return adtifUrl;
}

export async function getDesignAssets(adtifUrl: string, culture: string, tenant: Tenant): Promise<any> {
  const cultures = await getRequiredCultures(tenant);
  const escaped = escape(adtifUrl);
  const endpoint = `/api/v2/Localizations?adtifUrl=${escaped}`;
  const url = `${config.localizationUIServerUrl}${endpoint}`;

  const results = await fetchExportData(url);

  if (!results.ok) {
    //error
    throw new ApiError('get DesignAssets failed', results.status);
  }

  const isGuid = (candidate: string): boolean => {
    const r = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
    return r.test(candidate);
  };

  const json = await results.json();
  return json.map((x: any) => ({
    localizationId: x.localizationId,
    isNewPlatform: isGuid(x.localizationId),
    value: x.cultures[culture],
    adtif: adtifUrl,
    missingCultures: substract(
      cultures.map((c) => c.toLowerCase()),
      Object.keys(x.cultures),
    ),
  }));
}

const fetchExportData = async (url: string): Promise<Response> =>
  await fetch(url, {
    method: 'GET',
    headers: getDefaultHeaders(),
  });

const substract = (arr1: string[], arr2: string[]): string[] => arr1.filter((el) => !arr2.includes(el));
