import config from '../config';
import { getRequiredCultures } from './required-cultures';
import { getLocalizationContext } from './get-localization-context';
import { getDesignPreviewUrl } from './design-preview';
import { ApiError } from './ApiError';
import Logger from './../libs/Logging';
import { getDefaultHeaders } from './helpers/api-helpers';

export async function getLocalizationTasks(offset, limit, tenant) {
  const endpoint = `/api/v2/accounts/${tenant.accountId}/content-area/${tenant.areaId}/Localizations/`;
  const cultures = await getRequiredCultures(tenant);
  const queryString = getFilter(cultures, offset, limit);
  const url = `${config.localizationUrl}${endpoint}?${queryString}`;
  const results = await fetchLocalizations(url);

  if (!results.ok) {
    //error
    throw new ApiError('get missing localization failed', results);
  }

  const response = await results.json();

  const localizationsResult = await parseResponse(response, cultures);
  var localizationContext = {};
  try {
    localizationContext = await getLocalizationContext(localizationsResult.localizations.map((l) => l.localizationId));
  } catch (e) {
    Logger.logError('Failed to load context for missing localizations', e);
  }

  const getImageUrl = (adtifUrl) => {
    return adtifUrl ? getDesignPreviewUrl(adtifUrl) : null;
  };

  const localizationsWithContext = {
    ...localizationsResult,
    localizations: localizationsResult.localizations.map((l) => ({
      ...l,
      preview: getImageUrl(localizationContext[l.localizationId]?.[0]?.designUrl),
      adtif: localizationContext[l.localizationId]?.[0]?.designUrl,
    })),
  };

  return localizationsWithContext;
}

const fetchLocalizations = async (url) =>
  await fetch(url, {
    method: 'GET',
    headers: getDefaultHeaders(),
  });

const parseResponse = async (result, cultures) => {
  return {
    offset: result.offset,
    count: result.count,
    localizations: result._embedded.items.map((i) => {
      return {
        localizationId: i.id,
        defaultText: i.cultures['en-us']?.value ?? 'N/A',
        missingCultures: substract(
          cultures.map((c) => c.toLowerCase()),
          Object.keys(i.cultures),
        ),
      };
    }),
  };
};

const getFilter = (cultures, offset, limit) =>
  cultures.map((culture) => `requiredCulture=${culture.toLowerCase()}`).join('&') + `&offset=${offset}&limit=${limit}`;

const substract = (arr1, arr2) => arr1.filter((el) => !arr2.includes(el));
