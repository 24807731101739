import React, { useState } from 'react';
import { Table, Button } from '@cimpress/react-components';
import { useParams } from 'react-router-dom';
import RetranslationDialog from './RetranslationDialog';
import translationState from '../translationState';

export const ImportLocalizationList = ({ localizations, loading, culture, onSave }) => {
  let { accountId, areaId } = useParams();
  const [isRetranslationDialogVisible, setIsRetranslationDialogVisible] = useState(false);
  const [retranslationId, setRetranslationId] = useState('');
  const [retranslationValue, setRetranslationValue] = useState('');
  const [retranslationComment, setRetranslationComment] = useState('');
  const [retranslationLength, setRetranslationLength] = useState('');
  const [retranslationMode, setRetranslationMode] = useState('');

  const onRequestRetranslationClick = (targetLocalizationId, value) => {
    setRetranslationComment('');
    setRetranslationLength('');
    setRetranslationId(targetLocalizationId);
    setRetranslationMode('new');
    setIsRetranslationDialogVisible(true);
    setRetranslationValue(value);
  };

  const onRequestRetranslationUpdateClick = (targetLocalizationId, value, comment, length) => {
    setRetranslationComment(comment);
    setRetranslationLength(length);
    setRetranslationId(targetLocalizationId);
    setRetranslationMode('update');
    setIsRetranslationDialogVisible(true);
    setRetranslationValue(value);
  };

  const getStatus = (input) => {
    if (input.retranslation?.state === 'Requested') {
      return translationState.RETRANSLATION_REQUESTED;
    } else if (input.retranslation?.state === 'ExportedToExternalSystem') {
      return translationState.RETRANSLATION_EXPORTED_TO_EXTERNAL_SYSTEM;
    } else if (input.retranslation?.state === 'Imported') {
      return translationState.RETRANSLATION_IMPORTED;
    }

    return translationState.UNKNOWN;
  };

  const columns = [
    {
      Header: 'EN-US LOCALIZATION',
      id: 'text',
      accessor: (r) => <div dangerouslySetInnerHTML={{ __html: r.value }}></div>,
    },
    {
      Header: `${culture} LOCALIZATION`,
      id: 'translated',
      accessor: (r) => <div dangerouslySetInnerHTML={{ __html: r.translatedValue }}></div>,
    },
    {
      Header: '',
      id: 'action',
      disableSortBy: true,
      accessor: (r) => {
        let status = getStatus(r);
        if (status === translationState.UNKNOWN || status === translationState.RETRANSLATION_IMPORTED)
          return (
            <Button onClick={() => onRequestRetranslationClick(r.localizationId, r.value)} type="default">
              <div style={{ whiteSpace: 'normal' }}>Reject and request new translation</div>
            </Button>
          );
        if (status === translationState.RETRANSLATION_REQUESTED)
          return (
            <div>
              <span>
                New translation requested:
                <br />
              </span>
              <span style={{ fontStyle: 'italic' }}>{r.retranslation.comment}</span>
              <div style={{ fontStyle: 'italic' }}> Max Length: {r.retranslation.length}</div>
              <div>
                <Button
                  onClick={() =>
                    onRequestRetranslationUpdateClick(
                      r.localizationId,
                      r.value,
                      r.retranslation.comment,
                      r.retranslation.length,
                    )
                  }
                  type="default"
                >
                  Edit request description
                </Button>
              </div>
            </div>
          );
        if (status === translationState.RETRANSLATION_EXPORTED_TO_EXTERNAL_SYSTEM) return <div>Already exported</div>;
      },
    },
    {
      Header: ``,
      id: 'editExisting',
      width: 150,
      accessor: (r) => (
        <Button
          type="primary"
          href={`/Accounts/${accountId}/ContentArea/${areaId}/Localizations/${r.localizationId}`}
          target="_blank"
        >
          Details
        </Button>
      ),
    },
  ];

  return (
    <>
      {localizations && localizations.length > 0 ? (
        <Table
          columns={columns}
          data={localizations}
          InlineEdit={false}
          showPagination={false}
          sortable={false}
          minRows={0}
          pageSize={10}
          loading={loading}
        />
      ) : (
        <div>No text in the template</div>
      )}
      <RetranslationDialog
        localizationId={retranslationId}
        culture={culture}
        value={retranslationValue}
        isVisible={isRetranslationDialogVisible}
        closeDialog={() => setIsRetranslationDialogVisible(false)}
        onSave={() => {
          onSave();
        }}
        initialComment={retranslationComment}
        initialLength={retranslationLength}
        mode={retranslationMode}
      />
    </>
  );
};
