const translationState = {
  UNKNOWN: 'Unknown',
  WAITING_FOR_TRANSLATION: 'Waiting for translation',
  TRANSLATED: 'Translated',
  RETRANSLATION_REQUESTED: 'Retranslation requested',
  RETRANSLATION_EXPORTED_TO_EXTERNAL_SYSTEM: 'Retranslation exported to external system',
  RETRANSLATION_IMPORTED: 'Retranslation imported',
};

export default translationState;
