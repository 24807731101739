import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Card, Table } from '@cimpress/react-components';

export const ContentAreaPage = ({ accountId, onAccountInformation, redirectUrl }) => {
  const [account, setAccount] = useState();
  const [areaList, setAreaList] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const acc = onAccountInformation(accountId);
    setAccount(acc);
    const keys = Object.keys(acc.area);
    setAreaList(keys.map((key) => ({ ...acc.area[key], areaId: key })));
    setLoading(false);
  }, [onAccountInformation, accountId, account]);

  const cardHeader = (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      <span />
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        Account: {account?.name}
      </div>
    </div>
  );

  const columns = [
    { Header: 'Area Name', id: 'area', accessor: (row) => <Link to={redirectUrl(row.areaId)}>{row.name}</Link> },
  ];

  return (
    <>
      <Card header={cardHeader}>
        <Table
          columns={columns}
          data={areaList}
          InlineEdit={false}
          showPagination={false}
          sortable={false}
          minRows={0}
          pageSize={100}
          loading={loading}
        ></Table>
      </Card>
    </>
  );
};
