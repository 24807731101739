import config from '../config';
import { ApiError } from './ApiError';
import { getDefaultHeaders } from './helpers/api-helpers';

const chunkArray = (arr, chunksize) =>
  Object.keys(arr).reduce((c, k, i) => {
    if (i % chunksize === 0) {
      c.push(Object.fromEntries([[k, arr[k]]]));
    } else {
      c[c.length - 1][k] = arr[k];
    }
    return c;
  }, []);

export async function importMissingLocalization(tenant, culture, localizations) {
  var batches = chunkArray(localizations, 100);

  for (const batch of batches) {
    await importMissingLocalizationInternal(tenant, culture, batch);
  }
}

export async function importMissingLocalizationInternal(tenant, culture, localizations) {
  if (!Object.keys(localizations).length) return;

  const endpoint = `/api/v2/accounts/${tenant.accountId}/content-area/${tenant.areaId}/localizations/${culture}:import`;
  const url = `${config.localizationUIServerUrl}${endpoint}`;
  const response = await sendImportData(url, localizations);

  if (!response.ok) {
    if (response.status === 403) {
      throw new ApiError('Not enough permissions to import', response);
    }

    throw new ApiError('Import missing localization failed', response);
  }

  return response;
}

export const extractCultureFromFilename = (fileName) => fileName.split('.')[0].replace('_', '-').toLowerCase();

export function validateFile(fileName, importFile, requiredCultures) {
  const name = extractCultureFromFilename(fileName);
  const correctFileName = requiredCultures.map((c) => c.toLowerCase()).includes(name);

  if (!correctFileName) {
    return {
      isValid: false,
      message: `filename must be named according to imported culture. Eg.: es-ES. Provided name was ${name}`,
    };
  }

  try {
    JSON.parse(importFile);
  } catch {
    return { isValid: false, message: `file must be json` };
  }

  return { isValid: true, message: `` };
}

const sendImportData = async (url, data) => {
  return await fetch(url, {
    method: 'POST',
    body: JSON.stringify(data),
    headers: getDefaultHeaders(),
  });
};
