import SumoLogger from 'sumo-logger';
import config from '../config';
import auth from '../auth';

const loggingOptions = {
  endpoint: config.sumo.endpoint,
  sourceCategory: config.sumo.category,
  sourceName: config.sumo.name,
};

const logger = new SumoLogger(loggingOptions);

function getUserCanonicalId() {
  const profile = auth.getProfile();
  return (profile ?? {})['https://claims.cimpress.io/canonical_id'] ?? '(Unknown User ID)';
}

function log(severity, message, error) {
  const logCallResponse = error
    ? logger.log({ severity: severity, msg: message, userId: getUserCanonicalId(), cause: error })
    : logger.log({ severity: severity, msg: message, userId: getUserCanonicalId() });
  return logCallResponse;
}

export function logDebug(message) {
  return log('Debug', message);
}

export function logWarning(message, error) {
  return log('Warning', message, error);
}

export function logError(message, error) {
  return log('Error', message, error);
}

export function logInfo(message) {
  return log('Info', message);
}

export default {
  logError: logError,
};
