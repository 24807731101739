import config from '../config';
import { ApiError } from './ApiError';
import { getDefaultHeaders } from './helpers/api-helpers';

const endpoint = '/api/v1/Localizations';

export async function getLocalizations(culture, ids) {
  const chunks = sliceIntoChunks(ids, 20);
  let res = {};

  for (const chunk of chunks) {
    const localizations = await getLocalizationsInternal(culture, chunk);
    res = { ...res, ...localizations };
  }

  return res;
}

function sliceIntoChunks(arr, chunkSize) {
  const res = [];
  for (let i = 0; i < arr.length; i += chunkSize) {
    const chunk = arr.slice(i, i + chunkSize);
    res.push(chunk);
  }
  return res;
}
async function getLocalizationsInternal(culture, ids) {
  const idsToQueryString = (ids) => ids.map((id) => `id=${id}`).join('&');
  const url = `${config.localizationUrl}${endpoint}?cultureCode=${culture}&${idsToQueryString(ids)}`;
  const results = await fetchLocalizations(url);

  if (!results.ok) {
    //error
    throw new ApiError('get localization batch failed', results);
  }

  const response = await results.json();
  return response.reduce((acc, l) => {
    acc[l.id] = l.value;
    return acc;
  }, {});
}

const fetchLocalizations = async (url) =>
  await fetch(url, {
    method: 'GET',
    headers: getDefaultHeaders(),
  });
