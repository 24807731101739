import config, { ContentAccountIdConfiguration, ContentAreaDetails } from '../config';
import { ApiError } from './ApiError';

export function getAccount(accountId: string): ContentAccountIdConfiguration {
  const accountConfigurations = config.mockAccountsConfiguration;
  const account = accountConfigurations[accountId];

  if (!account) {
    const contentAccount: ContentAccountIdConfiguration = { name: accountId, area: {} };
    contentAccount.area[accountId] = { name: 'emptyAreaId', derivedCultures: {} };
    return contentAccount;
  }

  return account;
}

export function getArea(accountId: string, areaId: string): ContentAreaDetails {
  const account = getAccount(accountId);
  if (account.area) {
    const area = account.area[areaId];
    if (!area) {
      throw new ApiError('area was not found', { accountId, areaId });
    }
    return area;
  }

  return { name: `${accountId}-${areaId}`, derivedCultures: {} };
}
