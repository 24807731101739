import config from '../config';
import { ApiError } from './ApiError';
import { Tenant } from './model/tenant';
import { LocalizationsBatchDictionary } from './get-translations';
import { getDefaultHeaders } from './helpers/api-helpers';

export async function getAvailableTranslations(tenant: Tenant): Promise<LocalizationsBatchDictionary> {
  const endpoint = `/api/v2/accounts/${tenant.accountId}/content-area/${tenant.areaId}/Translations/`;
  const url = `${config.localizationUIServerUrl}${endpoint}`;

  const results = await fetch(url, {
    method: 'GET',
    headers: getDefaultHeaders(),
  });

  if (!results.ok) {
    //error
    throw new ApiError('get available translations failed', results);
  }

  return await results.json();
}
