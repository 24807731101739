import config from '../config';
import { groupBy } from '../libs/linq';
import { ApiError } from './ApiError';
import { getDefaultHeaders } from './helpers/api-helpers';

export interface DesignTemplateAsset {
  url: string;
  id: string;
  templateId: string;
  localizationId: string;
}

export async function getLocalizationContext(localizationIds: string[]): Promise<DesignTemplateAsset[]> {
  const queryString = localizationIds.map((id) => `localizationId=${id}`).join('&');
  const endpoint = `/api/v2/Localizations/Context?${queryString}`;

  const url = `${config.localizationUIServerUrl}${endpoint}`;
  const response = await getAsync(url);

  if (!response.ok) {
    //error
    throw new ApiError('get localization context failed', response);
  }

  const designTemplateAssets = await response.json();

  const assetsById = groupBy(designTemplateAssets, (asset: DesignTemplateAsset) => asset.localizationId);
  return assetsById;
}

const getAsync = async (url: string): Promise<Response> =>
  await fetch(url, {
    method: 'GET',
    headers: getDefaultHeaders(),
  });
