import React from 'react';
import { Table, Button } from '@cimpress/react-components';

const LocalizationList = ({ localizations, loading, onEdit }) => {
  const columns = [
    {
      Header: 'EN-US LOCALIZATION',
      id: 'text',
      accessor: (r) => <div dangerouslySetInnerHTML={{ __html: r.value }}></div>,
    },
    {
      Header: 'Missing cultures',
      id: 'missingCultures',
      width: 150,
      accessor: (r) => (r.isNewPlatform ? r.missingCultures.length : <i>Old platform</i>),
    },
    {
      Header: '',
      id: 'action',
      disableSortBy: true,
      width: 100,
      accessor: (r) => (
        <Button
          type="primary"
          onClick={() => {
            onEdit(r.localizationId);
          }}
          disabled={!r.isNewPlatform}
        >
          Detail
        </Button>
      ),
    },
  ];

  return (
    <>
      {localizations && localizations.length > 0 ? (
        <Table
          columns={columns}
          data={localizations}
          InlineEdit={false}
          showPagination={true}
          sortable={false}
          minRows={0}
          loading={loading}
        />
      ) : (
        <div>No text in the template</div>
      )}
    </>
  );
};

export default LocalizationList;
