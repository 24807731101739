import React from 'react';
import LocalizationList from './LocalizationList';
import { getDesignPreviewUrl } from '../api/design-preview';

const DesignTemplate = ({ adtif, localizations, loading, onEdit }) => {
  return (
    <div className="row" style={{ marginBottom: 15 }}>
      <div className="col-sm-4 col-md-2">
        <img src={getDesignPreviewUrl(adtif, 200)} alt="preview" style={{ border: '1px dotted #0092b5' }} />
      </div>
      <div className="col-sm-8 col-md-10">
        <LocalizationList localizations={localizations} loading={loading} onEdit={onEdit} />
      </div>
    </div>
  );
};

export default DesignTemplate;
