import { getDefaultHeaders } from './helpers/api-helpers';
import config from '../config';
import { ApiError } from './ApiError';
import { Tenant } from './model/tenant';

export interface Localization {
  value: string;
  adtif: string;
  _context: string;
  length?: number;
}

export type LocalizationsDictionary = { [localizationId: string]: Localization };
export type LocalizationsBatchDictionary = { [locale: string]: LocalizationsDictionary };

export async function getTranslations(tenant: Tenant, folder: string): Promise<LocalizationsBatchDictionary> {
  const endpoint = `/api/v2/accounts/${tenant.accountId}/content-area/${
    tenant.areaId
  }/Translations/${encodeURIComponent(folder)}/cultures`;
  const url = `${config.localizationUIServerUrl}${endpoint}`;

  const results = await fetch(url, {
    method: 'GET',
    headers: getDefaultHeaders(),
  });

  if (!results.ok) {
    //error
    throw new ApiError('get translations failed', results);
  }

  return await results.json();
}

export async function getTranslationsForCulture(
  tenant: Tenant,
  folder: string,
  culture: string,
): Promise<LocalizationsDictionary> {
  const endpoint = `/api/v2/accounts/${tenant.accountId}/content-area/${
    tenant.areaId
  }/Translations/${encodeURIComponent(folder)}/cultures/${culture}`;
  const url = `${config.localizationUIServerUrl}${endpoint}`;

  const results = await fetch(url, {
    method: 'GET',
    headers: getDefaultHeaders(),
  });

  if (!results.ok) {
    //error
    throw new ApiError('get translations failed', results);
  }

  return await results.json();
}
