import React, { useState, useEffect } from 'react';
import { useLocation, useHistory, useParams } from 'react-router-dom';
import { Alert, Button, Card } from '@cimpress/react-components';
import { getDesignPreviewUrl } from '../api/design-preview';
import { getTranslatedAdtif } from '../api/get-translated-adtif';
import Loading from './Loading';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
import IconTranslate from '@cimpress-technology/react-streamline-icons/lib/IconTranslate';
import config from '../config';
import { LocalizationsBatchDictionary } from '../api/get-translations';
import { Tenant } from '../api/model/tenant';
import { importLocalizationBatch } from '../api/import-localization-batch';
import { camBaseUrl, contentAuthoringAreaId } from '../config';
import { extractTemplateIdFromAdtifUrl } from './Helpers';

export interface Dictionary<T> {
  [Key: string]: T;
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
export const ImportByTemplate = ({ setToast, translations }) => {
  const history = useHistory();
  const { accountId, areaId, batchId } = useParams<{ accountId: string; areaId: string; batchId: string }>();
  const [localizations, setLocalizations] = useState<Dictionary<string[]>>({});
  const [loading, setLoading] = useState(true);
  const [isImporting, setIsImporting] = useState(false);
  const derivedCultures = config.mockAccountsConfiguration[accountId]?.area[areaId]?.derivedCultures ?? {};
  const derivedCulturesFlattened = Object.keys(derivedCultures).flatMap((key) => derivedCultures[key]);
  const [hasLocalizationsWithoutAdtif, setHasLocalizationsWithoutAdtif] = useState(false);

  useEffect(() => {
    convertToLocalizaionsByTemplate(translations);
  }, [translations]);

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  const query = useQuery();
  const isImported = query.get('imported');
  const convertToLocalizaionsByTemplate = async (translations: LocalizationsBatchDictionary) => {
    setLoading(true);

    const localizations = Object.entries(translations).map(([key, value]) => ({
      culture: key,
      items: value,
    }));

    const items = localizations.flatMap((l) =>
      Object.keys(l.items).map((key) => ({
        culture: l.culture,
        adtifUrl: l.items[key].adtif,
      })),
    );

    const itemsWithAdtifUrls = items.filter((l) => l.adtifUrl);

    const groupedByTemplate = itemsWithAdtifUrls.reduce(
      (acc: Dictionary<string[]>, i: { culture: string; adtifUrl: string }): Dictionary<string[]> => {
        if (!acc[i.adtifUrl]) {
          acc[i.adtifUrl] = [];
        }
        acc[i.adtifUrl] = [...acc[i.adtifUrl], i.culture];
        return acc;
      },
      {},
    );

    setLocalizations(groupedByTemplate);
    setLoading(false);
    setHasLocalizationsWithoutAdtif(items.length > itemsWithAdtifUrls.length);
  };

  const callProccessLocalization = async () => {
    try {
      setIsImporting(true);
      const tenant: Tenant = { accountId: accountId, areaId: areaId };
      await importLocalizationBatch(tenant, batchId);
      history.push(`/Accounts/${accountId}/ContentArea/${areaId}/Batches`);
    } catch (e: any) {
      setIsImporting(false);
      setToast([e.message], 'danger');
    }
  };

  return (
    <>
      {hasLocalizationsWithoutAdtif && (
        <>
          <div style={{ marginTop: '15px' }} />
          <Alert
            type="info"
            title="Localizations without templates"
            message="Localizations without template reference are not seen in the Template preview tab. To see complete list navigate to Texts tab."
            dismissible={false}
          />
        </>
      )}
      {Object.keys(localizations).map((key) => {
        const cultures = [...new Set(localizations[key])];
        const templateId = extractTemplateIdFromAdtifUrl(key);
        const items = cultures
          .filter((culture) => !derivedCulturesFlattened.includes(culture))
          .map((culture) => {
            const tenant = { accountId, areaId };
            const translatedCultureAdtif = getTranslatedAdtif(
              tenant,
              encodeURIComponent(batchId),
              encodeURIComponent(key),
              culture,
            );
            const preview = getDesignPreviewUrl(translatedCultureAdtif, 200);

            return (
              <div className="col-sm-3" key={culture}>
                <div>
                  <a
                    href={
                      isImported
                        ? `/Accounts/${accountId}/ContentArea/${areaId}/Import/Batches/${batchId}/Cultures/${culture}?adtifUrl=${encodeURIComponent(
                            key,
                          )}&imported=${isImported}`
                        : `/Accounts/${accountId}/ContentArea/${areaId}/Import/Batches/${batchId}/Cultures/${culture}?adtifUrl=${encodeURIComponent(
                            key,
                          )}`
                    }
                  >
                    <img src={preview} alt="preview"></img>
                  </a>
                  <div>
                    <span>{culture} - </span>
                    <a href={getDesignPreviewUrl(translatedCultureAdtif, 1920)} target="_blank">
                      preview
                    </a>
                  </div>
                </div>
              </div>
            );
          });

        return (
          <div key={key} className="row" style={{ marginTop: '15px' }}>
            <Card
              style={{ margin: '4px' }}
              header={
                <div>
                  <a
                    rel="noopener noreferrer"
                    href={`/Accounts/${accountId}/ContentArea/${areaId}/TemplatesTranslation/?filter=${key}`}
                    target="_blank"
                  >
                    Template Details <IconTranslate />
                  </a>
                  <span> - </span>
                  <a
                    href={`${camBaseUrl}/accounts/${accountId}/contentarea/${contentAuthoringAreaId}/designs?search=${templateId}`}
                    target="_blank"
                  >
                    CAM link
                  </a>
                </div>
              }
            >
              <div className="col-sm-3">
                <div>
                  <img src={getDesignPreviewUrl(key, 200)} alt="preview"></img>
                  <div>en-us</div>
                </div>
              </div>
              {items}
            </Card>
            {loading && <Loading />}
          </div>
        );
      })}
      <div>
        <Button
          onClick={() => callProccessLocalization()}
          style={{ margin: 5 }}
          type="primary"
          disabled={isImported || isImporting ? true : false}
        >
          {isImporting ? 'Import in progress' : 'Import all'}
        </Button>
        <Button
          onClick={() => history.push(`/Accounts/${accountId}/ContentArea/${areaId}/Batches`)}
          style={{ margin: 5 }}
        >
          Cancel
        </Button>
      </div>
    </>
  );
};
