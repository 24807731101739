import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { Card, TextField, Button } from '@cimpress/react-components';

const TemplateTranslationSelectionPage = () => {
  const history = useHistory();
  let { accountId, areaId } = useParams();

  const filterKey = 'templateTranslationPage_filter';

  const [filterText, setFilterText] = useState(localStorage.getItem(filterKey) ?? '');

  const onFilter = (filter) => {
    setFilterText(filter);
    history.push(`/Accounts/${accountId}/ContentArea/${areaId}/TemplatesTranslation/?filter=${filter}`);
    // localStorage.setItem('templateTranslationPage_mode', 'view');
    // localStorage.setItem('templateTranslationPage_filter', filter);

    //history.push(`TemplatesTranslation?filter=${filter}&mode=view`)
  };

  return (
    <>
      <div className="container-fluid designs-page">
        <h2>Batch Template Translation</h2>
        <div className="row">
          <div className="col-sm-12 col-md-12">
            <Card>
              <TextField
                name="Templates"
                label="Input designs. You can specify design by ID or ADTIF URL)."
                value={filterText}
                onChange={(e) => setFilterText(e.target.value)}
                type="textarea"
                rows={20}
              />
              <Button onClick={() => onFilter(filterText)} style={{ marginLeft: 20 }}>
                Continue
              </Button>
            </Card>
          </div>
        </div>
      </div>
    </>
  );
};

export default TemplateTranslationSelectionPage;
