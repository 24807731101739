const slashEncodeString = '%2F';
// To debug locally, change to 'dev':
const env = 'prod';

export const extractTemplateIdFromAdtifUrl = (adtif?: string): string | undefined => {
  if (!adtif) return undefined;

  if (adtif.includes(`${env}.designtemplate.content.cimpress.io`)) {
    adtif = adtif.replaceAll(slashEncodeString, '/');
    const matches = adtif.match('/designs/([0-9a-f-]*)');
    if (!matches) return undefined;
    const match = matches[0];
    const templateId = match.slice(match.lastIndexOf('/') + 1);
    return templateId;
  }
  return undefined;
};
