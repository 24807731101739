import React, { useState } from 'react';
import { Card } from '@cimpress/react-components';
import MissingLocalizations from './MissingLocalizations';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { exportMissingLocalization } from '../api/export-missing-localizations';

import { Toast } from './Toast';
import { Export } from './Export';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const LocalizationPage = () => {
  const [message, setMessage] = useState({ message: '', severity: 'info' });
  const history = useHistory();
  let query = useQuery();
  let { accountId, areaId } = useParams();

  const setToast = (messages, severity) => {
    var d = new Date();
    var version = d.toString();
    setMessage({ messages, severity, version });
  };

  const onEditClicked = (localizationId) => {
    const url = `/Accounts/${accountId}/ContentArea/${areaId}/Localizations`;
    history.push(`${url}/${localizationId}`);
  };

  const onPageChange = (page, pageSize) => {
    const url = `/Accounts/${accountId}/ContentArea/${areaId}/Localizations`;
    history.push(`${url}?currentPageSize=${pageSize}&currentPage=${page}`);
  };

  const load = async () => {
    return await exportMissingLocalization({ accountId, areaId }, 'en-us');
  };

  const cardHeader = (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      <span />
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Export setToast={setToast} loadData={async () => await load()} />
      </div>
    </div>
  );
  const getCurrentPage = () => parseInt(query.get('currentPage'));

  const getCurrentPageSize = () => parseInt(query.get('currentPageSize'));

  return (
    <>
      <div className="container-fluid designs-page">
        <div className="row">
          <div className="col-sm-12 col-md-10 col-md-offset-1">
            <h2>Missing Localizations</h2>
            <Card header={cardHeader}>
              <MissingLocalizations
                tenant={{ accountId, areaId }}
                currentPage={getCurrentPage()}
                currentPageSize={getCurrentPageSize()}
                onPageChange={onPageChange}
                onEdit={(localizationId) => onEditClicked(localizationId)}
                onError={(err) => setMessage({ message: err, severity: 'danger' })}
              />

              <Toast messages={message.messages} severity={message.severity} version={message.version} />
            </Card>
          </div>
        </div>
      </div>
    </>
  );
};

export default LocalizationPage;
