import React from 'react';
import { shapes } from '@cimpress/react-components';

const Loading = () => {
  return (
    <div className="container">
      <div className="row">
        <div className="col-md-12 text-center">
          <span>Authenticating</span>
          <shapes.Spinner />
        </div>
      </div>
    </div>
  );
};

export default Loading;
