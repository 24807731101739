import config from '../config';
import { ApiError } from './ApiError';
import { Retranslation } from './get-localization-retranslation-data';
import { getDefaultHeaders } from './helpers/api-helpers';

export default async function postRetranslation(localizationId: string, cultureCode: string, value: Retranslation) {
  const url = `${config.localizationUIServerUrl}/api/v2/accounts/${config.accountId}/content-area/${
    config.areaId
  }/ReTranslations/Localizations/${localizationId}/cultures/${cultureCode.toLowerCase()}`;
  const postResults = await fetch(url, {
    method: 'POST',
    headers: getDefaultHeaders(),
    body: JSON.stringify(value),
  });

  if (!postResults.ok && postResults.status === 403) {
    throw new ApiError(`Not enough permissions to send retranslate culture reuest`, postResults);
  }

  if (!postResults.ok && postResults.status === 404) {
    throw new ApiError(`ReTranslation was not found`, postResults);
  }

  if (!postResults.ok) {
    throw new ApiError(`Unexpected exception`, postResults);
  }

  return { text: value, culture: cultureCode };
}
