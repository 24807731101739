import React, { useState, useEffect, useCallback } from 'react';
import { useHistory, useParams, useLocation } from 'react-router-dom';

import { Card, Button } from '@cimpress/react-components';
import { getDTSDesignUrl, getAdtifUrl, getDesignAssets, getDesignId } from '../api/get-design-assets';
import { getDesignPreviewUrl } from '../api/design-preview';
import DesignTemplate from './DesignTemplate';
import { Toast } from './Toast';
import { Export } from './Export';

import { isUrl } from '../libs/utils';
import { useAsyncError } from '../libs/useAsyncError';
import { CustomError } from '../libs/CustomError';
import { logError, logWarning } from '../libs/Logging';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const TemplatesTranslationPage = () => {
  const history = useHistory();
  let { accountId, areaId } = useParams();
  const query = useQuery();
  const filterText = query.get('filter') || undefined;

  const filterKey = 'templateTranslationPage_filter';

  const [message, setMessage] = useState({ message: '', severity: 'info' });
  const [loading, setLoading] = useState(true);
  const [localizations, setLocalizations] = useState([]);

  const throwError = useAsyncError();

  const fetchDesignAssetsByAdtifUrl = useCallback(
    async (url) => {
      try {
        const designAssets = await getDesignAssets(url, 'en-us', { accountId, areaId });
        return {
          error: false,
          designAssets: designAssets,
        };
      } catch (e) {
        logError(`Error while getting template assets by url ${url}`, e);
        return {
          error: true,
          errorDetail: e,
          url: url,
        };
      }
    },
    [accountId, areaId],
  );

  const loadData = useCallback(
    async (filters) => {
      try {
        if (!filters) {
          return [];
        }

        const ids = filters.split(/[\s,]+/);

        const adtifUrls = ids
          .map((x) => x.trim())
          .filter((x) => x !== '')
          .map((x) => (isUrl(x) ? x : getAdtifUrl(getDTSDesignUrl(getDesignId(x)))));

        const normalizeAdtifUrls = [...new Set(adtifUrls)];

        const getAssetsTasks = normalizeAdtifUrls.map((id) => fetchDesignAssetsByAdtifUrl(id));

        const results = await Promise.all(getAssetsTasks);

        const assetsFlattened = results.filter((r) => !r.error).flatMap((x) => x.designAssets);

        const errors = results.filter((r) => r.error);

        if (errors.length > 0) {
          logWarning(`Failed loading templates`, errors);
          setToast(
            errors.map((e) => `Failed loading template for URL ${e.url}`),
            'danger',
          );
        }

        var assetsGrouped = [];
        normalizeAdtifUrls.forEach((adtif) => {
          assetsGrouped[adtif] = assetsFlattened.filter((asset) => asset.adtif === adtif);
        });

        return assetsGrouped;
      } catch (e) {
        throwError(new CustomError(`Error while getting template: ${filters}`, e));
      }
    },
    [fetchDesignAssetsByAdtifUrl, throwError],
  );

  useEffect(() => {
    setToast([], 'info');

    localStorage.setItem(filterKey, filterText);

    loadData(filterText).then((localizations) => {
      setLocalizations(localizations);
      setLoading(false);
    });
  }, [loadData, filterText]);

  const setToast = (messages, severity) => {
    var d = new Date();
    var version = d.toString();
    setMessage({ messages, severity, version });
  };

  const exportFile = () => {
    const items = Object.keys(localizations)
      .flatMap((key) => localizations[key])
      .filter((l) => l.missingCultures.length > 0);

    return items.reduce(
      (a, x) => ({
        ...a,
        [x.localizationId]: {
          value: x.value,
          _context: getDesignPreviewUrl(x.adtif),
        },
      }),
      {},
    );
  };

  const cardHeader = (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div>Showing {Object.keys(localizations).length} selected templates</div>
        <div>
          <Button onClick={() => onBack(filterText)} style={{ marginLeft: 20 }}>
            Change
          </Button>
        </div>
      </div>
      <div>
        <Export setToast={setToast} loadData={exportFile} showFilterForTest={false} />
      </div>
    </div>
  );

  const onBack = () => {
    history.push(`/Accounts/${accountId}/ContentArea/${areaId}/TemplatesTranslation/Select`);
    //localStorage.setItem('templateTranslationPage_mode', 'filter');
    //history.push(`TemplatesTranslation?filter=${filter}&mode=filter`)
  };

  const onEditClicked = (localizationId) => {
    history.push(`/Accounts/${accountId}/ContentArea/${areaId}/Localizations/${localizationId}`);
  };

  return (
    <>
      <div className="container-fluid designs-page">
        <h2>Batch Template Translation</h2>
        <div className="row">
          <div className="col-sm-12 col-md-12">
            <Card header={cardHeader}>
              {Object.keys(localizations).map((key) => (
                <DesignTemplate
                  adtif={key}
                  localizations={localizations[key]}
                  loading={loading}
                  onEdit={(localizationId) => onEditClicked(localizationId)}
                />
              ))}
              <Toast messages={message.messages} severity={message.severity} version={message.version} />
            </Card>
          </div>
        </div>
      </div>
    </>
  );
};

export default TemplatesTranslationPage;
