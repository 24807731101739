import { Tenant } from './api/model/tenant';

export type ContentAreaDetails = { name: string; derivedCultures: any };
export type ContentAreaIdConfiguration = { [areaId: string]: ContentAreaDetails };
export type ContentAccountIdConfiguration = { name: string; area: ContentAreaIdConfiguration };

export const defaultTenant: Tenant = { accountId: 'ozoDdrmewShEcbUDWX8J3V', areaId: 'IoBX4KKMc0uyUyA0tFQDg' };
export const camBaseUrl = 'https://cam.contentauthoring.cimpress.io';
// Used for calls to CAM:
export const contentAuthoringAreaId = 'BariCgbVOzbaYywM';

const mockAccountsConfiguration: { [accountId: string]: ContentAccountIdConfiguration } = {
  ozoDdrmewShEcbUDWX8J3V: {
    name: 'Vistaprint',
    area: {
      V0DJMs5nByPR6UD: {
        name: 'test',
        derivedCultures: {
          'de-de': ['de-at', 'de-ch'],
          'en-gb': ['en-ie', 'en-au', 'en–sg'],
          'fr-fr': ['fr-be'],
          'it-it': ['it-ch'],
          'nl-nl': ['nl-be'],
        },
      },
      IoBX4KKMc0uyUyA0tFQDg: {
        name: 'Product Design',
        derivedCultures: {
          'de-de': ['de-at', 'de-ch'],
          'en-gb': ['en-ie', 'en-au', 'en–sg'],
          'fr-fr': ['fr-be'],
          'it-it': ['it-ch'],
          'nl-nl': ['nl-be'],
        },
      },
    },
  },
  g2Ez5VaoZWoqU22XqPjTLU: {
    name: 'Cimpress',
    area: {
      V0DJMs5nByPR6UD: {
        name: 'test',
        derivedCultures: {},
      },
    },
  },
  sUwJnco1V7RtqbL6W8DuQ8: {
    name: 'Pixart',
    area: {
      S58aw8ckDU6NagJ9pUUDRg: {
        name: 'Pixart',
        derivedCultures: {},
      },
      gWunMgUpUEKRYxI7ZYxPwA: {
        name: 'test',
        derivedCultures: {},
      },
    },
  },
};

const local = {
  localizationUrl: 'https://dev.localization.content.cimpress.io',
  localizationUIServerUrl: 'https://localhost:5001',
  designTemplateServiceURl: 'https://dev.designtemplate.content.cimpress.io',
  accountId: 'ozoDdrmewShEcbUDWX8J3V',
  areaId: 'IoBX4KKMc0uyUyA0tFQDg',
  sumo: {
    endpoint:
      'https://endpoint2.collection.us2.sumologic.com/receiver/v1/http/ZaVnC4dhaV25yTy7Cp061Bz9GW8xMajP7UN6ojAzdfrtk5k3QJJjl8EvcSYO20Gxhnqxpl9MiDc_VTcmH-kFL71Z6l9u30O9iPKoAgqIm2mPvksNnwoEOA==',
    name: 'LocalizationUi/LOCAL',
    category: '260114/ContentManagement/ContentAssetManagement/LocalizationUi/LOCAL',
  },
};

const dev = {
  localizationUrl: 'https://dev.localization.content.cimpress.io',
  localizationUIServerUrl: 'https://dev.localization.contentauthoring.cimpress.io',
  designTemplateServiceURl: 'https://dev.designtemplate.content.cimpress.io',
  accountId: 'ozoDdrmewShEcbUDWX8J3V',
  areaId: 'IoBX4KKMc0uyUyA0tFQDg',
  sumo: {
    endpoint:
      'https://endpoint2.collection.us2.sumologic.com/receiver/v1/http/ZaVnC4dhaV25yTy7Cp061Bz9GW8xMajP7UN6ojAzdfrtk5k3QJJjl8EvcSYO20Gxhnqxpl9MiDc_VTcmH-kFL71Z6l9u30O9iPKoAgqIm2mPvksNnwoEOA==',
    name: 'LocalizationUi/DEV',
    category: '260114/ContentManagement/ContentAssetManagement/LocalizationUi/DEV',
  },
  //localizationUIServerUrl: "https://localhost:5001"
};

const prod = {
  localizationUrl: 'https://prod.localization.content.cimpress.io',
  localizationUIServerUrl: 'https://localization.contentauthoring.cimpress.io',
  designTemplateServiceURl: 'https://prod.designtemplate.content.cimpress.io',
  accountId: 'ozoDdrmewShEcbUDWX8J3V',
  areaId: 'IoBX4KKMc0uyUyA0tFQDg',
  sumo: {
    endpoint:
      'https://endpoint2.collection.us2.sumologic.com/receiver/v1/http/ZaVnC4dhaV25yTy7Cp061Bz9GW8xMajP7UN6ojAzdfrtk5k3QJJjl8EvcSYO20Gxhnqxpl9MiDc_VTcmH-kFL71Z6l9u30O9iPKoAgqIm2mPvksNnwoEOA==',
    name: 'LocalizationUi/PRD',
    category: '260114/ContentManagement/ContentAssetManagement/LocalizationUi/PRD',
  },
};

const config =
  process.env.REACT_APP_STAGE === 'production' ? prod : process.env.REACT_APP_STAGE === 'development' ? dev : local;

export default {
  ...config,
  mockAccountsConfiguration,
};
