import React from 'react';
import { Link, useParams } from 'react-router-dom';
import Header from '@cimpress-technology/react-platform-header/lib/Header';
import auth from '../auth';
import { getAccount, getArea } from '../api/get-content-area';

export const ContentHeader = (props) => {
  let { accountId, areaId } = useParams();
  const isTenantSpecified = () => accountId && areaId;
  const IsVistaprint = () => accountId === 'ozoDdrmewShEcbUDWX8J3V';

  const localizationLink = (
    <Link to={`/Accounts/${accountId}/ContentArea/${areaId}/Localizations`}>Missing Localizations</Link>
  );
  const templatesTranslationLink = (
    <Link to={`/Accounts/${accountId}/ContentArea/${areaId}/TemplatesTranslation/Select`}>
      Batch Template Translation
    </Link>
  );
  const importLink = <Link to={`/Accounts/${accountId}/ContentArea/${areaId}/Import`}>Import</Link>;
  const importS3Link = <Link to={`/Accounts/${accountId}/ContentArea/${areaId}/Batches`}>Import S3</Link>;

  const generateAppLinks = () => {
    return isTenantSpecified()
      ? IsVistaprint()
        ? [localizationLink, importLink, importS3Link, templatesTranslationLink]
        : [localizationLink, importLink, templatesTranslationLink]
      : [];
  };

  const areaName = isTenantSpecified() ? getArea(accountId, areaId).name : null;
  const accountName = getAccount(accountId).name;
  return (
    <>
      <Header
        accessToken={auth.getAccessToken()}
        appTitle={
          <>
            <Link to="/">{accountName}</Link>
            {areaName && <span> / {areaName} </span>}
          </>
        }
        appLinks={generateAppLinks()}
        profile={auth.getProfile()}
        onLogInClicked={() => auth.login()}
        onLogOutClicked={() => auth.logout('/')}
        isLoggedIn={auth.isLoggedIn}
      />
      {props.children}
    </>
  );
};
