import React, { useState } from 'react';
import { Card, Alert } from '@cimpress/react-components';

import { useParams } from 'react-router-dom';
import LocalizationDetail from './LocalizationDetail';
import LocalizationEdit from './LocalizationEdit';

const LocalizationDetailPage = () => {
  let { id, accountId, areaId } = useParams();
  const listMode = { operation: 'list', culture: undefined };
  const addMode = (editculture) => {
    return { operation: 'add', culture: editculture };
  };
  const editMode = (editculture, oldvalue) => {
    return {
      operation: 'edit',
      culture: editculture,
      oldvalue: oldvalue,
    };
  };

  const [error, setError] = useState('');
  const [mode, setMode] = useState(listMode);

  const onAddClicked = (editculture) => {
    setMode(addMode(editculture));
  };
  const onEditClicked = (editculture, oldvalue) => {
    setMode(editMode(editculture, oldvalue));
  };

  const cardHeader = <div>Localization key: {id}</div>;

  const editControl = (
    <>
      {(mode.operation === 'add' || mode.operation === 'edit') && (
        <LocalizationEdit
          onError={setError}
          id={id}
          oldvalue={mode.oldvalue}
          culture={mode.culture}
          operation={mode.operation}
          onComplete={() => setMode(listMode)}
          tenant={{ accountId, areaId }}
        />
      )}
    </>
  );

  return (
    <>
      <div className="container-fluid designs-page">
        <div className="row">
          <div className="col-sm-12 col-md-10 col-md-offset-1">
            <h2>Localization Detail</h2>
            <Card header={cardHeader}>
              {error && <Alert title="Error loading localizations" message={error} dismissible={false} />}
              {editControl}
              <LocalizationDetail
                id={id}
                onAdd={(editculture) => onAddClicked(editculture)}
                onEdit={(editculture, editvalue) => onEditClicked(editculture, editvalue)}
                onError={(err) => setError(err)}
                operation={mode.operation}
                tenant={{ accountId, areaId }}
              />
            </Card>
          </div>
        </div>
      </div>
    </>
  );
};

export default LocalizationDetailPage;
