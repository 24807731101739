import React, { useState } from 'react';
import { Button } from '@cimpress/react-components';
import logger from './../libs/Logging';

export const Export = ({ setToast, loadData }) => {
  const [exporting, setExporting] = useState(false);

  const exportFileName = () => {
    var currentdate = new Date();

    return (
      'en-us_' + currentdate.getFullYear() + '-' + (currentdate.getMonth() + 1) + '-' + currentdate.getDate() + '.json'
    );
  };

  const exportMemsource = async () => {
    try {
      setExporting(true);
      const content = await loadData(); // exportMissingLocalization('en-us');

      const keys = Object.keys(content);

      const contentWithContext = keys.reduce(
        (a, x) => ({
          ...a,
          [x]: {
            ...content[x],
          },
        }),
        {},
      );

      const a = document.createElement('a');
      const file = new Blob([JSON.stringify(contentWithContext)], { type: 'application/json' });
      a.href = URL.createObjectURL(file);
      const fileName = exportFileName();
      a.download = fileName;
      a.click();
      setToast([`Missing localization exported to file '${fileName}'`], 'info');
    } catch (e) {
      setToast([`Missing localization export failed`], 'danger');
      logger.logError(`Missing localization export failed`, e);
    }

    setExporting(false);
  };

  return (
    <>
      <Button onClick={() => exportMemsource()} disabled={exporting} style={{ marginLeft: 20 }}>
        {exporting ? '... Export In Progress ...' : 'Export Missing Localizations'}
      </Button>
    </>
  );
};
