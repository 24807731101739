import React, { useState, useEffect } from 'react';
import './App.css';
import auth from './auth';
import Loading from './Components/Loading';
import LocalizationDetailPage from './Components/LocalizationDetailPage';
import LocalizationPage from './Components/LocalizationPage';
import PageNotFound from './Components/PageNotFound';
import { ContentHeader } from './Components/ContentHeader';
import { CssLoader } from '@cimpress/react-components';
import ErrorBoundary from './Components/ErrorBoundary';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import TemplatesTranslationPage from './Components/TemplatesTranslationPage';
import TemplateTranslationSelectionPage from './Components/TemplateTranslationSelectionPage';
import { ContentAreaPage } from './Components/Mutlitenancy/ContentAreaPage';
import { ImportPage } from './Components/ImportPage';
import { ImportPageS3 } from './Components/ImportPageS3';
import { ImportBatchesPage } from './Components/ImportBatchesPage';
import { ImportTemplateDetailPage } from './Components/ImportTemplateDetailPage';
import { defaultTenant } from './config';

import { getAccount } from './api/get-content-area';
import Logger from './libs/Logging';

function App() {
  const [authenticating, setAuthenticating] = useState(true);
  const [loggedIn, setLoggedIn] = useState(false);
  const [authenticationError, setAuthenticationError] = useState();

  const ensureLoggedIn = () => {
    if (!loggedIn) {
      auth
        .ensureAuthentication({ nextUri: window.location.pathname + window.location.search })
        .then((res) => {
          setAuthenticating(false);
          setLoggedIn(res);
        })
        .catch((error) => {
          Logger.logError('Could not authenticate', error);
          setAuthenticating(false);
          setAuthenticationError(error);
        });
    }
  };

  useEffect(ensureLoggedIn);

  const getMyAccount = () => {
    return auth.getProfile()['https://claims.cimpress.io/account'];
  };

  return (
    <div className="App">
      {authenticating ? (
        <Loading />
      ) : loggedIn ? (
        <Router>
          <CssLoader>
            <ErrorBoundary>
              <Switch>
                <Route exact path="/(index.html)?">
                  <Redirect to={`/Accounts/${defaultTenant.accountId}/ContentArea/${defaultTenant.areaId}/Batches`} />
                </Route>
                <Route exact path="/Accounts/:accountId/ContentArea/:areaId/TemplatesTranslation/Select">
                  <ContentHeader>
                    <TemplateTranslationSelectionPage />
                  </ContentHeader>
                </Route>
                <Route path="/Accounts/:accountId/ContentArea/:areaId/TemplatesTranslation">
                  <ContentHeader>
                    <TemplatesTranslationPage />
                  </ContentHeader>
                </Route>
                <Route path="/Accounts/:accountId/ContentArea/:areaId/Localizations/:id">
                  <ContentHeader>
                    <LocalizationDetailPage />
                  </ContentHeader>
                </Route>
                <Route path="/Accounts/:accountId/ContentArea/:areaId/Localizations">
                  <ContentHeader>
                    <LocalizationPage />
                  </ContentHeader>
                </Route>
                <Route exact path="/Accounts/:accountId/ContentArea/:areaId/Import">
                  <ContentHeader>
                    <ImportPage />
                  </ContentHeader>
                </Route>
                <Route exact path="/Accounts/:accountId/ContentArea/:areaId/Batches">
                  <ContentHeader>
                    <ImportBatchesPage />
                  </ContentHeader>
                </Route>
                <Route exact path="/Accounts/:accountId/ContentArea/:areaId/Batches/:batchId">
                  <ContentHeader>
                    <ImportPageS3 />
                  </ContentHeader>
                </Route>
                <Route
                  exact
                  path="/Accounts/:accountId/ContentArea/:areaId/Import/Batches/:batchId/Cultures/:cultureId"
                >
                  <ContentHeader>
                    <ImportTemplateDetailPage />
                  </ContentHeader>
                </Route>

                <Route path="/Accounts/:accountId/ContentArea">
                  <ContentHeader>
                    <ContentAreaPage
                      accountId={getMyAccount()}
                      onAccountInformation={getAccount}
                      redirectUrl={(areaId) => `/Accounts/${getMyAccount()}/ContentArea/${areaId}/Batches`}
                    />
                  </ContentHeader>
                </Route>
                <Route>
                  <ContentHeader>
                    <PageNotFound />
                  </ContentHeader>
                </Route>
              </Switch>
            </ErrorBoundary>
          </CssLoader>
        </Router>
      ) : authenticationError ? (
        <div>Unexpected error encountered. {authenticationError.message}</div>
      ) : null}
    </div>
  );
}

export default App;
