import config from '../config';
import { getRequiredCultures } from './required-cultures';
import { ApiError } from './ApiError';
import { getLocalizationRetranslationData, Retranslation } from './get-localization-retranslation-data';
import { Tenant } from './model/tenant';
import { getDefaultHeaders } from './helpers/api-helpers';

export type CultureLocalizationDictionary = { [cultureCode: string]: string };

export type LocalizationData = {
  id: string;
  cultures: { [cultureCode: string]: { value: string; exactMatch: boolean } };
};

async function getLocalizationData(localizationId: string): Promise<LocalizationData> {
  const url = `${config.localizationUrl}/api/v2/Localizations/${localizationId}`;
  const results = await fetch(url, {
    method: 'GET',
    headers: getDefaultHeaders(),
  });

  if (!results.ok) {
    //error
    throw new ApiError('get localization failed', results.status);
  }

  return await results.json();
}

interface LocalizationIdInfo {
  culture: string;
  text: string;
  retranslation: Retranslation;
  originalText: string;
}

export async function getLocalization(localizationId: string, tenant: Tenant): Promise<LocalizationIdInfo[]> {
  const requiredCultures = await getRequiredCultures(tenant);
  const localization = await getLocalizationData(localizationId);
  const localizationRestranslationData = await getLocalizationRetranslationData(localizationId);
  const originalText = localization.cultures['en-us']?.value ?? '';

  const mapped = requiredCultures.map((culture) => {
    const cultureSmall = culture.toLowerCase();
    const text = localization.cultures[cultureSmall]?.value ?? '';
    const retranslation = localizationRestranslationData[cultureSmall];

    return { culture, text, retranslation, originalText };
  });

  return mapped;
}

function isGuid(value: string): boolean {
  if (value[0] === '{') {
    value = value.substring(1, value.length - 1);
  }
  const regexGuid = /^(\{){0,1}[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}(\}){0,1}$/gi;
  return regexGuid.test(value);
}

export function isValidLocalizationId(localizationId: string): boolean {
  return isGuid(localizationId);
}
