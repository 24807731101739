import config from '../config';
import { Culture } from './add-culture';
import { ApiError } from './ApiError';
import { getDefaultHeaders } from './helpers/api-helpers';
import { Tenant } from './model/tenant';

export default async function updateCulture(
  localizationId: string,
  cultureCode: string,
  value: string,
  tenant: Tenant,
): Promise<Culture> {
  const url = `${config.localizationUIServerUrl}/api/v2/accounts/${tenant.accountId}/content-area/${tenant.areaId}/Localizations/${localizationId}/cultures`;
  const postResults = await fetch(url, {
    method: 'POST',
    headers: getDefaultHeaders(),
    body: JSON.stringify({ value, cultureCode }),
  });

  if (!postResults.ok && postResults.status === 403) {
    throw new ApiError(`Not enough permissions to update culture`, postResults);
  }

  if (!postResults.ok && postResults.status === 404) {
    throw new ApiError(`Translation was not found`, postResults);
  }

  if (!postResults.ok) {
    throw new ApiError(`Unexpected exception`, postResults);
  }

  return { code: cultureCode, exactMatch: true };
}
