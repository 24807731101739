import config from '../config';
import { ApiError } from './ApiError';
import { getDefaultHeaders } from './helpers/api-helpers';
import { Tenant } from './model/tenant';

export type Culture = { code: string; exactMatch: boolean };

export async function addCulture(
  localizationId: string,
  cultureCode: string,
  value: string,
  tenant: Tenant,
): Promise<Culture> {
  const url = `${config.localizationUIServerUrl}/api/v2/accounts/${tenant.accountId}/content-area/${tenant.areaId}/Localizations/${localizationId}/cultures`;

  const postResults = await fetch(url, {
    method: 'POST',
    headers: getDefaultHeaders(),
    body: JSON.stringify({ cultureCode, value }),
  });

  if (!postResults.ok) {
    if (postResults.status === 403) {
      throw new ApiError('Not enough permissions to add culture', postResults);
    }

    throw new ApiError('add culture failed', postResults);
  }

  return { code: cultureCode, exactMatch: true };
}
