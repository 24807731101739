import { ApiError } from './ApiError';
import config from '../config';
import { getDefaultHeaders } from './helpers/api-helpers';

export interface Retranslation {
  localizationId: string;
  culture: string;
  comment: string;
  length?: number;
  value: string;
  state: string; // TODO add retranslation states based on ReTranslationStatusResponseDto.cs in server
}

export type RetranslationDictionary = {
  [culture: string]: Retranslation;
};
export async function getLocalizationRetranslationData(localizationId: string): Promise<RetranslationDictionary> {
  const url = `${config.localizationUIServerUrl}/api/v2/accounts/${config.accountId}/content-area/${config.areaId}/ReTranslations/localizations/${localizationId}`;

  const results = await fetch(url, {
    method: 'GET',
    headers: getDefaultHeaders(),
  });

  if (!results.ok) {
    //error
    throw new ApiError('get retranslation status failed ', results);
  }

  return await results.json();
}
