import config from '../config';
import { ApiError } from './ApiError';
import { getDefaultHeaders } from './helpers/api-helpers';
import { Tenant } from './model/tenant';

export async function getRequiredCultures(tenant: Tenant): Promise<string[]> {
  const endpoint = `/api/v2/accounts/${tenant.accountId}/content-area/${tenant.areaId}/Cultures/`;
  const url = `${config.localizationUIServerUrl}${endpoint}`;
  const results = await fetchCultures(url);

  if (!results.ok) {
    throw new ApiError('get cultures per tenant', results.status);
  }

  return await results.json();
}

const fetchCultures = async (url: string): Promise<Response> =>
  await fetch(url, {
    method: 'GET',
    headers: getDefaultHeaders(),
  });
