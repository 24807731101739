import React, { useState, useEffect, useCallback } from 'react';
import { TextField, Button, ButtonGroup } from '@cimpress/react-components';
import { addCulture } from '../api/add-culture';
import updateCulture from '../api/update-culture';
import { getLocalization, isValidLocalizationId } from '../api/get-localization';
import { useAsyncError } from '../libs/useAsyncError';
import { CustomError } from '../libs/CustomError';
import { logError } from '../libs/Logging';

const LocalizationEdit = ({ id, oldvalue, culture, operation, onError, onComplete, tenant }) => {
  const [value, setValue] = useState(oldvalue);
  const [valueConfirmed, setValueConfirmed] = useState();
  const throwError = useAsyncError();

  const fetchLocalizationAsync = useCallback(
    async (id) => {
      try {
        if (isValidLocalizationId(id)) {
          const localization = await getLocalization(id, tenant);
          const v = localization.filter((l) => l.culture === culture)[0] ?? { text: '' };
          setValue(v.text);
        } else {
          onError(`${id} is not valid localization key.`);
        }
      } catch (e) {
        throwError(new CustomError(`Error while ediing localization: ${id}`, e));
      }
    },
    [culture, onError, tenant, throwError],
  );

  useEffect(() => {
    if (operation === 'edit') {
      fetchLocalizationAsync(id);
    }
  }, [fetchLocalizationAsync, id, operation]);

  useEffect(() => {
    if (valueConfirmed) {
      const apiFunction = operation === 'edit' ? updateCulture : addCulture;

      apiFunction(id, culture, valueConfirmed, tenant)
        .then(() => {
          onComplete();
        })
        .catch((e) => {
          logError(`Error while updating localization id: ${id}, culture: ${culture}, value: ${value}`, e);
          onError(e.message);
        });
    }
  }, [culture, id, onComplete, onError, operation, tenant, value, valueConfirmed]);

  return (
    <div style={{ marginBottom: 15 }}>
      <TextField name="localization" value={value} label={culture} onChange={(e) => setValue(e.target.value)} />
      <ButtonGroup type="justified">
        <Button style={{ minWidth: '100px' }} type="primary" onClick={() => setValueConfirmed(value)}>
          Save
        </Button>
        <Button style={{ minWidth: '100px' }} type="link" onClick={() => onComplete()}>
          Cancel
        </Button>
      </ButtonGroup>
    </div>
  );
};

export default LocalizationEdit;
