import React, { useState } from 'react';
import { Card } from '@cimpress/react-components';
import { Toast } from './Toast';
import { Import } from './Import';
import { useHistory, useParams } from 'react-router-dom';

export const ImportPage = () => {
  const [message, setMessage] = useState({ message: '', severity: 'info' });
  const history = useHistory();
  let { accountId, areaId } = useParams();

  const setToast = (messages, severity) => {
    var d = new Date();
    var version = d.toString();
    setMessage({ messages, severity, version });
  };

  const cardHeader = <div>Import From File</div>;
  const localizationsUrl = `/Accounts/${accountId}/ContentArea/${areaId}/Localizations`;

  return (
    <>
      <div className="container-fluid designs-page">
        <div className="row">
          <div className="col-sm-12 col-md-10 col-md-offset-1">
            <Card header={cardHeader}>
              <Import
                setToast={setToast}
                onComplete={() => history.push(localizationsUrl)}
                tenant={{ accountId, areaId }}
              />
              <Toast messages={message.messages} severity={message.severity} version={message.version} />
            </Card>
          </div>
        </div>
      </div>
    </>
  );
};
