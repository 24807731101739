import React, { useState, useEffect, useCallback } from 'react';
import { Table, Button } from '@cimpress/react-components';
import { getLocalization, isValidLocalizationId } from '../api/get-localization';
import { useAsyncError } from '../libs/useAsyncError';
import { CustomError } from '../libs/CustomError';
import RetranslationDialog from './RetranslationDialog';
import translationState from '../translationState';

const LocalizationDetail = ({ id, onEdit, onAdd, onError, operation, tenant }) => {
  const [localizations, setLocalizations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isRetranslationDialogVisible, setIsRetranslationDialogVisible] = useState(false);
  const [retranslationCulture, setRetranslationCulture] = useState('');
  const [retranslationComment, setRetranslationComment] = useState('');
  const [retranslationLength, setRetranslationLength] = useState('');
  const [retranslationMode, setRetranslationMode] = useState('');

  const [version, setVersion] = useState(0);

  const throwError = useAsyncError();

  const getStatus = (input) => {
    if (input.text === '') {
      return translationState.WAITING_FOR_TRANSLATION;
    } else if (!input.retranslation) {
      return translationState.TRANSLATED;
    } else if (input.retranslation.state === 'Requested') {
      return translationState.RETRANSLATION_REQUESTED;
    } else if (input.retranslation.state === 'ExportedToExternalSystem') {
      return translationState.RETRANSLATION_EXPORTED_TO_EXTERNAL_SYSTEM;
    } else if (input.retranslation.state === 'Imported') {
      return translationState.RETRANSLATION_IMPORTED;
    }

    return translationState.UNKNOWN;
  };

  const fetchLocalizationAsync = useCallback(async () => {
    const allTextsAreEmpty = (texts) => texts.every((x) => !x || x.text === '');

    try {
      if (isValidLocalizationId(id)) {
        const localization = await getLocalization(id, tenant);
        setLocalizations(allTextsAreEmpty(localization) ? [] : localization);
      } else {
        setLocalizations([]);
        onError(`${id} is not valid localization key.`);
      }
      setLoading(false);
    } catch (e) {
      throwError(new CustomError(`Error while getting localization details for: ${id}`, e));
    }
  }, [id, onError, tenant, throwError]);

  useEffect(() => {
    console.log(version); // don't delete this is needed for static analysis.
    fetchLocalizationAsync();
  }, [fetchLocalizationAsync, version]);

  const disableButtons = () => operation === 'edit' || operation === 'add';

  const onRequestRetranslationClick = (targetCulture) => {
    setRetranslationComment('');
    setRetranslationLength('');
    setRetranslationCulture(targetCulture);
    setRetranslationMode('new');
    setIsRetranslationDialogVisible(true);
  };

  const onRequestRetranslationUpdateClick = (targetCulture, comment, length) => {
    setRetranslationCulture(targetCulture);
    setRetranslationComment(comment);
    setRetranslationLength(length);
    setRetranslationMode('update');
    setIsRetranslationDialogVisible(true);
  };

  const columns = [
    { Header: 'Culture', id: 'culture', accessor: 'culture', width: 100 },
    {
      Header: 'Text',
      id: 'text',
      disableSortBy: true,
      accessor: (r) => <div dangerouslySetInnerHTML={{ __html: r.text }}></div>,
    },
    {
      Header: '',
      id: 'action',
      disableSortBy: true,
      width: 100,
      accessor: (r) => (
        <Button
          disabled={disableButtons()}
          type="primary"
          onClick={() => {
            r.text ? onEdit(r.culture, r.text) : onAdd(r.culture);
          }}
        >
          Edit
        </Button>
      ),
    },
    {
      Header: '',
      id: 's.status',
      disableSortBy: true,
      style: { verticalAlign: 'middle', textAlign: 'left' },
      width: 250,
      accessor: (r) => {
        let status = getStatus(r);
        if (status === translationState.TRANSLATED || status === translationState.RETRANSLATION_IMPORTED) {
          return (
            <Button disabled={disableButtons()} onClick={() => onRequestRetranslationClick(r.culture)} type="default">
              Request new translation
            </Button>
          );
        }
        if (status === translationState.RETRANSLATION_REQUESTED) {
          return (
            <div>
              <span>
                New translation requested:
                <br />
              </span>
              <span style={{ fontStyle: 'italic' }}>{r.retranslation.comment}</span>
              <div style={{ fontStyle: 'italic' }}> Max Length: {r.retranslation.length}</div>
              <div>
                <Button
                  style={{ minWidth: '100px' }}
                  type="anchor"
                  onClick={() =>
                    onRequestRetranslationUpdateClick(r.culture, r.retranslation.comment, r.retranslation.length)
                  }
                >
                  Edit request description
                </Button>
              </div>
            </div>
          );
        }
        if (status === translationState.RETRANSLATION_EXPORTED_TO_EXTERNAL_SYSTEM) {
          return (
            <div>
              <span>
                Retranslation exported to external system:
                <br />
              </span>
              <span style={{ fontStyle: 'italic' }}>{r.retranslation.comment}</span>
              <div style={{ fontStyle: 'italic' }}> Max Length: {r.retranslation.length}</div>
            </div>
          );
        }
        if (status === translationState.WAITING_FOR_TRANSLATION) {
          return translationState.WAITING_FOR_TRANSLATION;
        }
        return translationState.UNKNOWN;
      },
    },
  ];

  return (
    <>
      <Table
        columns={columns}
        data={localizations}
        InlineEdit={false}
        showPagination={false}
        sortable={false}
        minRows={0}
        pageSize={100}
        loading={loading}
      ></Table>
      <RetranslationDialog
        localizationId={id}
        culture={retranslationCulture}
        value={localizations.length > 0 ? localizations[0].originalText : ''}
        isVisible={isRetranslationDialogVisible}
        closeDialog={() => setIsRetranslationDialogVisible(false)}
        onSave={() => {
          setVersion(version + 1);
        }}
        initialComment={retranslationComment}
        initialLength={retranslationLength}
        mode={retranslationMode}
      />
    </>
  );
};

export default LocalizationDetail;
