export const getDesignPreviewUrl = (url: string, width = 330): string => {
  // TODO: why is 330 hardcoded?
  if (!url) {
    return '';
  }

  const documentUri = `https://uds.documents.cimpress.io/v2/transient/vp?type=preview&cacheBust=${Date.now()}&documentUri=${url}`;
  const documentUriEncoded = encodeURIComponent(documentUri);
  const imageUri = `https://rendering.mcp.cimpress.com/v1/vp/preview?format=jpeg&width=${width}&instructions_uri=${documentUriEncoded}`;
  return imageUri;
};
