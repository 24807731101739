import React, { useEffect, useState } from 'react';
import { Button, Table } from '@cimpress/react-components';
import { getLocalizations } from '../api/get-localization-batch';
import { useLocation, useHistory, useParams } from 'react-router-dom';
import { LocalizationsBatchDictionary } from '../api/get-translations';
import { Dictionary } from './ImportByTemplate';
import { importLocalizationBatch } from '../api/import-localization-batch';

type groupedByLocalizationId = {
  [Key: string]: Dictionary<string>;
};

type LocalizationItemData = {
  localizationId: string;
  translations: Dictionary<string>;
};

interface Props {
  setToast: any;
  translations: LocalizationsBatchDictionary;
  retranslation: any[];
}

export const ImportByText = ({ setToast, translations, retranslation }: Props): JSX.Element => {
  const history = useHistory();
  const { accountId, areaId, batchId } = useParams<{ accountId: string; areaId: string; batchId: string }>();
  const [localizationItems, setLocalizationItems] = useState<LocalizationItemData[]>([]);
  const [importedCultures, setImportedCultures] = useState<string[]>([]);
  const [enUsTranslations, setEnUsTranslations] = useState({});
  const [loading, setLoading] = useState(true);
  const [isImporting, setIsImporting] = useState(false);

  const disabledStyle = { opacity: 0.5, 'text-decoration': 'line-through' };

  useEffect(() => {
    convertToLocalizations(translations);
  }, [translations]);

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  const query = useQuery();
  const isImported = query.get('imported');
  const convertToLocalizations = async (translations: LocalizationsBatchDictionary) => {
    setLoading(true);

    const localizations = Object.entries(translations).map(([key, value]) => ({
      culture: key,
      items: value,
    }));

    const items = localizations.flatMap((l) =>
      Object.keys(l.items).map((key) => ({
        culture: l.culture,
        localizationId: key,
        localization: l.items[key].value,
        previewUrl: l.items[key]._context,
        adtifUrl: l.items[key].adtif,
      })),
    );

    const groupedByLocalizationId = items.reduce((acc: groupedByLocalizationId, i): groupedByLocalizationId => {
      if (!acc[i.localizationId]) {
        acc[i.localizationId] = {};
      }
      acc[i.localizationId][i.culture] = i.localization;
      return acc;
    }, {});

    const localizationItems = Object.keys(groupedByLocalizationId).map((localizationId) => ({
      localizationId: localizationId,
      translations: groupedByLocalizationId[localizationId],
    }));

    const ids = [...new Set(items.map((i) => i.localizationId))];
    const defaultEnUsTranslation = await getLocalizations('en-us', ids);

    setEnUsTranslations(defaultEnUsTranslation);

    const importedCultures = localizations.map((l) => l.culture);

    setImportedCultures(importedCultures);
    setLocalizationItems(localizationItems);
    setLoading(false);
  };

  const callProccessLocalization = async () => {
    try {
      setIsImporting(true);
      const tenant = { accountId, areaId };
      await importLocalizationBatch(tenant, batchId);
      history.push(`/Accounts/${accountId}/ContentArea/${areaId}/Batches`);
    } catch (e: any) {
      setIsImporting(false);
      setToast([e.message], 'danger');
    }
  };

  const HasRetranslationRequest = (localizationId: string, culture: string, retranslation: any) => {
    const state = retranslation[localizationId]?.[culture]?.state;
    return state === 'Requested' || state === 'ExportedToExternalSystem';
  };

  const columns = [
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    { Header: 'EN-US', id: '.EN-US', accessor: (r: any) => enUsTranslations[r.localizationId] },
    ...importedCultures?.map((culture) => ({
      Header: culture,
      id: culture,
      minWidth: 100,
      accessor: (r: any) =>
        HasRetranslationRequest(r.localizationId, culture.toLowerCase(), retranslation) ? (
          <div className="wordwrap" style={disabledStyle}>
            {r.translations[culture.toLowerCase()]}
          </div>
        ) : (
          <div className="wordwrap">{r.translations[culture.toLowerCase()]}</div>
        ),
    })),
  ];

  return (
    <>
      <div style={{ marginTop: '15px' }}>
        {
          <Table
            columns={columns}
            data={localizationItems}
            showPagination={true}
            sortable={true}
            minRows={0}
            pageSize={50}
            loading={loading}
            className="-striped -highlight"
            style={{ overflowX: 'auto' }}
          ></Table>
        }
      </div>
      <div>
        <Button
          onClick={() => callProccessLocalization()}
          style={{ margin: 5 }}
          type="primary"
          disabled={isImported || isImporting ? true : false}
        >
          {isImporting ? 'Import in progress' : 'Import all'}
        </Button>
        <Button
          onClick={() => history.push(`/Accounts/${accountId}/ContentArea/${areaId}/Batches`)}
          style={{ margin: 5 }}
        >
          Cancel
        </Button>
      </div>
    </>
  );
};
