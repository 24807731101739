import { Snackbar } from '@cimpress/react-components';
import React, { useEffect, useState } from 'react';

export const Toast = ({ messages, severity, version }) => {
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [displayMessages, setDisplayMessages] = useState('');

  useEffect(() => {
    if (!messages) {
      return;
    }

    setDisplayMessages(messages);
    setShowSnackbar(messages.length > 0);
  }, [messages, version]);

  return (
    <Snackbar
      show={showSnackbar}
      bsStyle={severity}
      delay={5000}
      onHideSnackbar={() => {
        setShowSnackbar(false);
      }}
    >
      <ul>{displayMessages && displayMessages.map((m) => <li>{m}</li>)}</ul>
    </Snackbar>
  );
};
